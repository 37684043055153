/** Enum for display-friendly data type names */
export enum DataType {
  Precondition = "Precondition",
  Connection = "Connection",
  Effect = "Effect",
  Validation = "Validation",
  ValidationConfiguration = "ValidationConfiguration",
  Action = "Action",
  ActionSection = "Action Section",
  GlobalAction = "Global Action",
  State = "State",
  Transition = "Transition",
  ModelField = "Field",
  DataModel = "Model",
  SourceFile = "File",
}
